.filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 0;
}

.search-button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.search-button:hover {
  background-color: #0056b3;
}
