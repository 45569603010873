.App {
  text-align: center;
  font-family: Arial, sans-serif;
}

.filter {
  margin: 20px 0;
}

.search-results {
  margin: 20px 0;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.result-item {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 15px;
  margin: 10px 0;
  width: 80%;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}